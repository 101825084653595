//
// _demos.scss
//

// Lightbox

.mfp-popup-form {
  max-width: 1140px;
}
.mfp-close {
  color: $dark !important;
}

.add-btn-position {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

// Modals

// .custom-modal-body{
//   padding:20px;
//   height:680px;
// }
// .custom-modal-body-small{
//   padding:20px;
//   // height:360px;
// }
// @media screen and (max-width: 767px){
//   .custom-modal-body-small{
//     height:480px;
//     background:"red";
//   }
// }

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

[dir='rtl'] {
  .modal-open {
    padding-left: 0px !important;
  }
}

.custom-trash-icon {
  margin-right: -40px;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: $gray-600;
  transition: all 0.4s;
  cursor: pointer;
}

.custom-trash-icon:hover {
  color: $primary;
  transform: scale(1.2);
}

.alert-icon {
  padding: 5px 7px 5px 0;
}

.custom-icon {
  font-size: 16px;
  padding: 5px 7px;
  color: $gray-600;
  transition: all 0.4s;
  cursor: pointer;
}

.custom-icon:hover {
  color: $primary;
  transform: scale(1.2);
}

button:disabled > .custom-icon,
button:disabled > .custom-icon:hover {
  font-size: 16px;
  padding: 5px 7px;
  color: $gray-400;
  transform: unset;
}

// Icon demo ( Demo only )
.icon-demo-content {
  text-align: center;
  color: $gray-500;

  i {
    display: block;
    font-size: 24px;
    margin-bottom: 16px;
    color: $gray-600;
    transition: all 0.4s;
  }

  .col-lg-4 {
    margin-top: 24px;

    &:hover {
      i {
        color: $primary;
        transform: scale(1.5);
      }
    }
  }
}

// Grid

.grid-structure {
  .grid-container {
    background-color: $gray-100;
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: $fw-medium;
    padding: 10px 20px;
  }
}

// card radio

.card-radio {
  background-color: $card-bg;
  border: 2px solid $card-border-color;
  border-radius: $border-radius;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.card-radio-label {
  display: block;
}

.card-radio-input {
  display: none;
  &:checked + .card-radio {
    border-color: $primary !important;
  }
}

.navs-carousel {
  .owl-nav {
    margin-top: 16px;
    button {
      width: 30px;
      height: 30px;
      line-height: 28px !important;
      font-size: 20px !important;
      border-radius: 50% !important;
      background-color: rgba($primary, 0.25) !important;
      color: $primary !important;
      margin: 4px 8px !important;
    }
  }
}

@keyframes custom-fadein {
  from {
    opacity: 1;
    // margin-bottom:30px;
  }
  to {
    opacity: 0;
    // margin-bottom:0px;
  }
}
