.table {
  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.custom-table {
  text-align: center;
  overflow-y: scroll;
  align-items: center;
  &::-webkit-scrollbar {
    display: hidden !important;
  }
}

.custom-add-role-table {
  text-align: center;
}

.custom-update-role-table {
  text-align: center;
  margin-top: -10px;
  max-height: 450px;
  height: 500px;
}

@media screen and (min-width: 767px) {
  .custom-table {
    max-height: 390px;
  }
  .custom-update-role-table {
    height: 500px;
  }
  .btn-wrapper {
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .custom-table {
    max-height: 320px;
  }
  .btn-wrapper {
    display: flex;
    justify-content: flex-start;
  }
}
//AddRoleDialog
@media screen and (max-width: 350px) {
  .custom-update-role-table {
    max-height: 300px;
    align-items: center;
  }
  .btn-wrapper {
    display: flex;
    justify-content: flex-start;
  }
}
