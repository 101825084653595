.react-daterange-picker__wrapper {
  border: none;
  * {
    color: rgba(255, 255, 255, 0.7);
    stroke: rgba(255, 255, 255, 0.4);
  }
}
.react-calendar {
  background-color: #32394f;
  border-radius: 10px;
  border: none;
}

.react-calendar__month-view__days__day--weekend {
  color: rgb(166, 176, 207);
}

.react-calendar__navigation button:disabled,
.react-calendar__tile:disabled {
  background-color: #32394f;
  color: #7277879b;
}

.react-calendar__tile--now {
  background-color: #32394f;
  abbr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button:enabled:hover,
.react-calendar__tile--active,
.react-calendar--selectRange .react-calendar__tile--hover,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive {
  background-color: #556ee6;
  color: white;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: none;
}

.react-daterange-picker__inputGroup__leadingZero {
  margin-bottom: 10px;
}
